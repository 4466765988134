import RegionList from '../assets/csv/regions_dpts_communes/regions-france_encoded.csv'
import DptList from '../assets/csv/regions_dpts_communes/departements-france_encoded.csv'

export const RegionDptSortMixin = {
    data() {
        return {
            location_region_options : [],
            location_dpt_options : []
        }
    },
    methods : {
        initRegionCSVTab() {
            // Push csv files, REGIONS list, into usable array (needed by vue-bootstrap)
            //this.location_region_options.push({value: null, text: 'Sélectionnez une région', disabled: true})
            this.location_region_options.push({value: undefined, text: 'Toute la france'})
            for (const region in RegionList) {
                // Correcting number parsing issue, turning it into string to re-add 0 before unique digit
                let regValue = parseInt(this.fixCVSNumberParsing(RegionList[region].code_region));
                let regText = RegionList[region].nom_region;

                this.location_region_options.push({
                        value: regValue,
                        text: regText
                })
            }
        },
        fixCVSNumberParsing(number_csv) {
            number_csv = number_csv.toString()
            if(number_csv.length == 1) {
                number_csv = '0' + number_csv
            }
            return number_csv
        },
        sortDpt(selected_Region) {
            // Take only the region number to compare it in departement/region.
          
            // Prevent array for stacking differents region/dpt
            this.form.location_dpt = null
            this.location_dpt_options = []

            // Default displayed value
            this.location_dpt_options.push({value: null, text: 'Sélectionnez un département', disabled: true})
            
            // Fix number display with fixCVSNumberParsing
            for (const dpt in DptList) {
                let regionCode = parseInt(this.fixCVSNumberParsing(DptList[dpt].code_region))

                if(selected_Region == regionCode) {
                    let deptValue = parseInt(this.fixCVSNumberParsing(DptList[dpt].code_departement));
                    let deptText = DptList[dpt].nom_departement;
                    this.location_dpt_options.push(
                        {
                            value: deptValue,
                            text: deptText,
                        }
                        
                    )
                }
            }
            
        }
    }
}
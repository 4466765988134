import Const from "../model/Const.js"


export const CategoryMixin = {
    data() {
        return {
            optionsCategorie: [],
            optionsSouscategories:[],
        }
    },
    methods : {
        initCategoryCSVTab(allAllowed) {
            this.optionsCategorie = [];
            //this.optionsCategorie.push({value: null, text: 'Sélectionnez une catégorie', disabled: true});
            if (allAllowed){
                this.optionsCategorie.push({value: undefined, text: 'Toute catégorie'});
            }
            this.optionsCategorie.push(...Const.getCategories());

        }, 
        loadSubcategories(category,allAllowed) {
            // Reset selected value and previous sub category filtering to prevent stacking
            this.form.subCategory = null;
            this.optionsSouscategories = [];
            this.optionsSouscategories.push({value: null, text: 'Sélectionnez une sous-catégorie', disabled: true})
            if (allAllowed){
                this.optionsSouscategories.push({value: undefined, text: 'Toute sous-catégorie'})
            }
            Const.getSousCategories().forEach(sc =>(sc.cat ==category)?this.optionsSouscategories.push(sc):""); 
        }
    },
 
}
<template>
    <div class="here-map-component-container">
      <div class="mainDiv">
        <b-input-group v-if="searchModule" class="buttonGroup">
          <img @click="locateMe()" class="targetIcon" src='../../../assets/icons/target.svg'/>
          <b-form-input placeHolder="Saisissez votre adresse ou votre ville" class="searchAddress" v-model="searchAddress" list="my-list-id"></b-form-input>

          <datalist class="mydatalist" id="my-list-id" ref="mydatalist">
            <option v-for="s in suggestionsHere" v-bind:key="s.id" v-bind:value="s.title" v-bind:label="s.title"></option>
          </datalist>
        <img class="loupe" src='../../../assets/icons/iconLoupeWhite.svg'/>
        </b-input-group>
          <div class="map" id="mapContainer" ref="hereMap" :style="getAdditionalMapStyle()"></div>
          </div>
           <ModalInfoComponent id="modalInfoNoGeolocation" ref="modalInfoNoGeolocation" :modalMessage="infoModalText" :okFunc="infoOk"/>
          <!-- <ModalMap id="modalMap" ref="modalMap"/>-->
    </div>
</template>

<script>
import ModalInfoComponent from '../../shared-component/modal-info-component/modal-info-component.vue'
//import ModalMap from '../../shared-component/modal-map/modal-map.vue'

export default {
    name: "HereMap",
    components: {
        ModalInfoComponent,//ModalMap,
    },
    props: {
        center: Object,
        fireAddressSet: undefined,
        searchModule: {
            type: Boolean,
            default: false,
        }        
    },
    data() {
        return {
            effectiveCenter: Object,
            infoModalText:'',
            automaticAddressSet:false,
          location:null,
          gettingLocation:false,
            searchAddress: '',
            platform: null,
           //apikey: "lR-dG6knhDPpCxzqigbVFdn0sqp2ZmeS2Ulw4C1Y4vg",
           apikey: "1uDHyVL9L1OoWjbJFJ8BTWlMLqEpprJp-HE5nP6NS3Y",
            map :null,
            geocoder : null,
            rest_apiKey : '1uDHyVL9L1OoWjbJFJ8BTWlMLqEpprJp-HE5nP6NS3Y',
            inputCity : '', // Valeur du champ 'Ville'
            suggestionsHere: [], // Tableau qui contiendra les suggestions Here
            suggestionSelected : '' // Ville & Code postal sélectionnés
        }
    },
    watch: {
      'searchAddress': function(newVal, oldVal) {
          if (this.automaticAddressSet){
              console.info("searchAddress changed due to automatic location, we don't upgrade address Choices.")
              this.automaticAddressSet = false;
          } else {
               console.log('searchAddress changed from ' + oldVal + ' to ' + newVal);
        this.updateAddressChoices(newVal);
          }
       
      }
    },
    async mounted() {
        const platform = new window.H.service.Platform({
            apikey: this.apikey
        });
        this.platform = platform;
        this.initializeHereMap();
    },

    created(){
        this.effectiveCenter = this.center;
    },
    methods: {
       infoOk(){

        },
        getAdditionalMapStyle(){
            if (this.searchModule){
                 return "margin-top:-50px; height:256px;";
            }
            return "cursor: pointer;";
        },
    
        updateAddressChoices(value){
          if (value != undefined && value != '') {
                // Call API Suggestions de HERE pour réécupérer les informations
                fetch(`https://autosuggest.search.hereapi.com/v1/autosuggest?at=46.56,2.58&limit=10&apikey=${this.rest_apiKey}&q=${value}`)
                    .then(result => result.json())
                    .then(result => {
                        this.suggestionsHere = []

                        if (result.items && result.items.length > 0 && result.items[0].position) {
                            this.suggestionsHere = result.items;
                            this.getInfosForLocation(this.suggestionsHere[0].position.lat,this.suggestionsHere[0].position.lng).then((locationInfo)=>{
                                let postalCode = locationInfo.postalCode;
                                 this.setCenter(this.suggestionsHere[0].position,postalCode);
                                 this.refresh();
                            });
                           
                        }
                    }, error => {
                        console.error(error);
                    });
            } else {
                this.suggestionsHere = []
            }
        },

        addCircleToMap(map,latitude,longitude){
            const H = window.H;
            map.addObject(new H.map.Circle(
                // The central point of the circle
                {lat:latitude, lng:longitude},
                // The radius of the circle in meters
                400,
                {
                style: {
                    strokeColor: 'rgba(44, 138, 251, 0.9)', // Color of the perimeter
                    lineWidth: 5,
                    fillColor: 'rgba(44, 138, 251, 0.24)'  // Color of the circle
                }
                }
            ));
        },

        initializeHereMap() { 
            // rendering map
            const mapContainer = this.$refs.hereMap;
            const H = window.H;
            this.geocoder = this.platform.getGeocodingService();
            // Obtain the default map types from the platform object
            let maptypes = this.platform.createDefaultLayers();

            // Instantiate (and display) a map object:
            this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
                zoom: 14,
                center: this.effectiveCenter
            });

            addEventListener("resize", () => this.map.getViewPort().resize());

            // add behavior control
           /* let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
            behavior.disable(H.mapevents.Behavior.DRAGGING);
            behavior.disable(H.mapevents.Behavior.WHEELZOOM);
            behavior.disable(H.mapevents.Behavior.DBLTAPZOOM);*/

            // add UI
            let ui = H.ui.UI.createDefault(this.map, maptypes);
            ui.getControl('zoom').setVisibility(false);
            ui.getControl('mapsettings').setVisibility(false);
            console.info("fireAddressSet = " + this.fireAddressSet);
            this.refresh();
        },
        setCenter(c,postalCode) {
          if (c != undefined){
             this.effectiveCenter = c;
             this.map.setCenter(c,false);//true = animation, false=no animation
             //this.map.removeObjects(this.map.getObjects ())
             //this.addCircleToMap(this.map,c.lat,c.lng);
             if (this.fireAddressSet){
                 this.fireAddressSet(c.lat,c.lng,postalCode);
             }
          }
           

        },
        getInfosForLocation(latitude,longitude){
            /*return new Promise((resolve, reject) => {
                this.geocoder.reverseGeocode(
                        {
                            prox: latitude+','+longitude+',250721',
                            maxresults: '1',
                            gen: '9',
                            mode:'retrieveAreas',
                            limit: 1,
                            at: latitude + "," + latitude
                        }, data => {
                            console.info("data = " + data.Response.View[0].Result[0].Location.Address.Label);
                            resolve({label:data.Response.View[0].Result[0].Location.Address.Label,postalCode:data.Response.View[0].Result[0].Location.Address.PostalCode});
                        }, error => {
                            console.error(error);
                            reject(error);
                        }
                    );
            });*/
            
        return new Promise((resolve, reject) => {
                fetch(`https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude},${longitude}&limit=10&apikey=${this.rest_apiKey}`)
                    .then(result => result.json(), error => {
                            console.error(error);
                            reject(error);
                        })
                    .then(result => {
                      console.info(result.items + " found");
                      if (result.items && result.items.length > 0) {
                          console.info("adresse trouve : " + result.items[0].title);
                          resolve({label:result.items[0].title,postalCode:result.items[0].address.postalCode});
                      } else {
                          console.info("adresse non trouvee pour " + latitude + " , " + longitude);
                          resolve( undefined);
                      }
                    })
            });
        
        },
        refresh(){
            this.map.getViewPort().resize(); 
        },

        onClickSuggestHere(suggestion) {
            // On renseigne la ville sélectionner
            this.suggestionSelected = suggestion.lib;

            // On reset la recherche
            this.inputCity = '';
            this.suggestionsHere = [];
        },

        async getLocation() {
            return new Promise((resolve, reject) => {
                if(!("geolocation" in navigator)) {
                reject(new Error('Geolocation is not available.'));
                }

                navigator.geolocation.getCurrentPosition(pos => {
                resolve(pos);
                }, err => {
                reject(err);
                });
            });
        },

        async locateMe() {
          this.gettingLocation = true;
          try {
              this.gettingLocation = false;
              this.location = await this.getLocation();
             /* this.getInfosForLocation(this.location.coords.latitude,this.location.coords.longitude).then((locationInfo)=>{
                console.info("locationInfo = " + locationInfo.Response.View[0].Result[0].Location.Address.Label);
              });*/
              this.getNearestLocation(this.location.coords.latitude,this.location.coords.longitude).then((address)=>{
                  console.info("address = " + address);
                  this.automaticAddressSet = true;
                  this.searchAddress = address.label;
                   this.setCenter({lat:this.location.coords.latitude,lng:this.location.coords.longitude},address.postalCode);
              this.refresh();
              });
             
          } catch(e) {
              this.gettingLocation = false;
              this.errorStr = e.message;
              this.infoModalText = "Impossible de récupérer votre position : " + this.errorStr;
              this.$nextTick(() => {
                     this.$refs.modalInfoNoGeolocation.showModal();
                });
              
          }
        },

        getNearestLocation(lat,long){
          if (lat != undefined && long != undefined) {
                // Call API Suggestions de HERE pour réécupérer les informations
                console.info("getNearestLocation(" + lat + " , " + long);
               
 
                /*return fetch(`https://places.sit.ls.hereapi.com/places/v1/discover/here?at=${lat},${long}&size=10&apikey=${this.rest_apiKey}`)
                    .then(result => result.json())
                    .then(result => {
                      console.info(result.results.items + " found");
                      if (result.results.items && result.results.items.length > 0) {
                          console.info("adresse trouve : " + result.results.items[0].title);
                          return result.results.items[0].title;
                      } else {
                          console.info("adresse trouve : " + result.search.context.location.address.text);
                          return undefined;
                      }
                    }).then(result => {
                        if (result){
                            return this.getInfosForLocation(lat,long).then((locationInfo)=>{
                                return {label:result,postalCode:locationInfo.postalCode};
                            });
                        } else {
                            return this.getInfosForLocation(lat,long);
                        }
                    });*/

                return fetch(`https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat},${long}&limit=10&apikey=${this.rest_apiKey}`)
                    .then(result => result.json())
                    .then(result => {
                      console.info(result.items + " found");
                      if (result.items && result.items.length > 0) {
                          console.info("adresse trouve : " + result.items[0].title);
                          return {label:result.items[0].title,postalCode:result.items[0].address.postalCode};
                      } else {
                          console.info("adresse non trouvee pour " + lat + " , " + long);
                          return undefined;
                      }
                    }).then(result => {
                        if (result){
                            return result;
                        } else {
                            return this.getInfosForLocation(lat,long);
                        }
                    });
            } else {
                return Promise.resolve();
            } 
        },
    }
};
</script>

<style lang="scss">
    @import "here-map-component.scss";
</style>